import { API_CHANGE_PASSWORD, API_FORGOTTEN_PASSWORD, API_ME, API_RESET_PASSWORD } from "Routes/apiRoutes";
import { apiRequest } from "functions/api/apiRequest/apiRequest";
import { generateUrl } from "functions/router/urlGenerator";
import { useLocation, useNavigate } from "react-router-dom"
import { User } from "type/entityTypes";
import { PasswordChangeData, PasswordCreateData } from "type/formTypes";

export const useLoadUser = (): (() => Promise<User>) => {
    const history = useNavigate();
    const location = useLocation();

    const loadUser = () => {
        return apiRequest(API_ME, {}, 'GET', history, location);
    }

    return loadUser;
};

export const usePostPassword = (): ((formData: PasswordCreateData, token: string) => Promise<any>) => {
    const history = useNavigate();
    const location = useLocation();

    const postPassword = (formData: PasswordCreateData, token: string) => {
        return apiRequest(API_RESET_PASSWORD + '/' + token, formData, 'POST', history, location);
    };

    return postPassword;
};

export const useUpdatePassword = (): ((formData: PasswordChangeData) => Promise<any>) => {
    const history = useNavigate();
    const location = useLocation();

    const updatePassword = (formData: PasswordChangeData) => {
        return apiRequest(API_CHANGE_PASSWORD, formData, 'POST', history, location);
    };

    return updatePassword;
};

export const useForgottenPasswordRequest = (): ((email: string) => Promise<any>) => {
    const history = useNavigate();
    const location = useLocation();

    const forgottenPasswordRequest = (email: string) => {
        return apiRequest(API_FORGOTTEN_PASSWORD, {
            email,
            target: generateUrl('password_reset', {}, true)//absolute url of reset_password page to generate email link
        }, 'POST', history, location);
    };

    return forgottenPasswordRequest;
};
