import { useQuery } from "@tanstack/react-query";
import { loadMenu } from "functions/customHooks/api/queries/menuQueries";
import { PropsWithChildren, createContext, useContext } from "react";

type MenuContextType = {
    menu: string[],
    isInitialized: boolean
};


const MenuContext = createContext<MenuContextType|null>(null);


type ProviderProps = PropsWithChildren<{
    defaultMenu: string[]
}>

export const MenuProvider = ({defaultMenu, children}: ProviderProps) => {

    const {data: menu} = useQuery({
        queryKey: ['menu'],
        queryFn: loadMenu,
        initialData: null
    });
    
    return (
        <MenuContext.Provider value={{
            menu: menu ? defaultMenu.concat(menu): defaultMenu,
            isInitialized: menu !== null
        }}>
            {children}
        </MenuContext.Provider>
    )
}


export const useMenu = (): MenuContextType => {
    const context = useContext(MenuContext);
    if(!context) {
        throw new Error('Menu context is not defined. You must wrap your component with <MenuProvider>.');
    }
    return context;
}
