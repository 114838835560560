import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

//Import Icons
import { generateUrl } from "functions/router/urlGenerator";
import { useMenu } from "Components/Contexts/MenuContext";
import { haveArraysCommonValue } from "functions/arrayHelpers/commonValuesChecker";
import { useGetUser } from "functions/customHooks/useGetUser";

type MenuState = {
    firstLevel: string,
    secondLevel: string|null,
    thirdLevel: string|null,
    [key: string]: string|null
};

const getVisibleMenuItems = (menuItems: any): any => {

    return menuItems.filter((menuItem: any) => menuItem.isVisible)
                    .map((menuItem:any) => {
                        if(!menuItem.subItems) {
                            return menuItem;
                        }
                        return {
                            ...menuItem,
                            subItems: menuItem.subItems.filter((subItem: any) => subItem.isVisible)
                                                        .map((subItem: any) => {
                                                            if(!subItem.childItems) {
                                                                return subItem;
                                                            }
                                                            return {
                                                                ...subItem,
                                                                childItems: subItem.childItems.filter((childItem: any) => childItem.isVisible)
                                                            }
                                                        }) 
                        }
                    });
};


const Navdata = () => {
    
    const {menu: originalMenu} = useMenu();
    const {pathname} = useLocation();

    //custom behaviour (must be deleted after roles will be managed by back-end as on fsp, and page users removed from default menu)
    //TO_DELETE //TO_REMOVE
    const {user} = useGetUser();
    const menu = useMemo(() => {
        if(originalMenu && user && !haveArraysCommonValue(user.roles, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])) {
            return originalMenu.filter((menuItem: string) => menuItem !== 'users');
        }
        return originalMenu;
    }, [user, originalMenu]);

    useEffect(() => {
        //firstLevel
        if(pathname.includes('administration')) {
            //secondLevel
            if(pathname.includes('administration/users')) {
               setMenuState({
                   firstLevel: 'admin',
                   secondLevel: 'users',
                   thirdLevel: null
               });
           }
       } else if(pathname.includes('items')) {
            setMenuState({
                firstLevel: 'items',
                secondLevel: null,
                thirdLevel: null
            });
       } else {
            setMenuState({
                firstLevel: 'home',
                secondLevel: null,
                thirdLevel: null
            });
        }
    }, [pathname]);
    

    const [menuState, setMenuState] = useState<MenuState>({
        firstLevel: 'home',
        secondLevel: null,
        thirdLevel: null
    });

    const changeMenuState = (level: string, value: string|null) => {
        setMenuState(prevMenuState => ({
            ...prevMenuState,
            [level]: value
        }));
    }
    const toggleMenuState = (level: string, value: string|null) => {
        setMenuState(prevMenuState => ({
            ...prevMenuState,
            [level]: prevMenuState[level] === value ? null: value
        }));
    }

    const menuItems: any = [
        {
            label: "",
            isHeader: true,
            isVisible: true,
        },
        {
            id: "home",
            label: "dashboard",
            icon: <i className="ri ri-home-4-line"></i>,
            link: generateUrl('home'),
            click: () => changeMenuState('firstLevel', 'home'),
            active: menuState.firstLevel === 'home',
            isVisible: menu.includes('home'),
        },
        {
            id: "items",
            label: "items",
            icon: <i className="ri ri-gift-line"></i>,
            link: generateUrl('items'),
            click: () => changeMenuState('firstLevel', 'items'),
            active: menuState.firstLevel === 'items',
            isVisible: menu.includes('items'),
        },
        {
            id: "administration",
            label: "administration",
            icon: <i className = "ri ri-admin-line"></i>,
            link: "/#",
            click: (e: any) => {
                e.preventDefault();
                toggleMenuState('firstLevel', 'admin');
            },
            active: menuState.firstLevel === 'admin',
            isVisible: haveArraysCommonValue(['users'], menu),
            subItems: [
                {
                    id: "users",
                    label: "users",
                    link: generateUrl('users'),
                    click: () => changeMenuState('secondLevel', 'users'),
                    active: menuState.secondLevel === 'users',
                    parentId: "admin",
                    isVisible: menu.includes('users')
                },
            ]
        },
    ];

    const visibleMenuItems = getVisibleMenuItems(menuItems);

    return <React.Fragment>{visibleMenuItems}</React.Fragment>;
};
export default Navdata;
