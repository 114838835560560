import { useNavigate } from "react-router-dom";
import { LogoDark } from "../LogoDark";
import { LogoLight } from "../LogoLight";
import { MouseEvent } from "react";
import { generateUrl } from "functions/router/urlGenerator";

export const MainLogo = () => {
    const navigate = useNavigate();
    const handleClick = (e: MouseEvent) => {
        e.preventDefault();
        navigate(generateUrl('home'));
    }

    return (
        <div style={{display: 'flex', justifyContent: 'center', cursor: 'pointer'}}>
            <div className="mt-3 position-relative" >
                <a href="/" onClick={handleClick}>
                    <LogoDark />
                    <LogoLight />
                </a>
            </div>
        </div>
    )
}
