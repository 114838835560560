import { useTranslation } from "react-i18next";

import logo from "../../../assets/images/custom/logo/logo_ferry.png";
import { AppConfig } from "config/AppConfig";

export const LoginCompanyTitle = () => {
    const {t} = useTranslation();

    return (
        <>
            <div className="auth-logo-wrapper">
                <div className="auth-logo" style={{marginTop: '80px'}}>
                    <img src={logo} alt={t('logo')} height={80} />
                </div>
            </div>
            <p className="mt-3 fs-15 fw-medium">{AppConfig.COMPANY_NAME} back-office</p>
        </>
    )
}
