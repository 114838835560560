//MENU
export const API_MENU = "/api/menu";

//AUTH
export const API_LOGIN =  "/api/login_check";
export const API_ME = "/api/me";
export const API_RESET_PASSWORD = "/api/auth/reset_password";
export const API_CHANGE_PASSWORD = "/api/auth/change_password";
export const API_FORGOTTEN_PASSWORD = "/api/auth/forgotten_password";

//NOTIFICATIONS
export const API_NOTIFICATIONS = "/api/notifications";

//ENTITY_ATTACHMENTS
export const API_ENTITY_ATTACHMENTS = '/api/entity_attachments'

//ENTITIES
export const API_USERS = "/api/users";

export const API_COUNTRIES = "/api/countries";

//FERRY

export const API_ITEMS = "/api/items";
export const API_ITEM_PICTURES = "/api/items/pictures";
export const API_ITEM_PICTURES_DOWNLOAD = "/api/items/pictures/download";
